import React, { useEffect, useRef, useState } from 'react'
import Text from '../../../components/Text'
import Container from '../../../components/Container'
import BackButton from '../../../components/Button/Back'
import ApiRequest from '../../../global/Api'
import Loading from '../../../components/Loading'
import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js'
import { Line } from 'react-chartjs-2'
import { styleguide } from '../../../global/Theme'
import Column from '../../../components/Column'
import Button from '../../../components/Button'
import Input from '../../../components/Input/Text'
import { moneyValue } from '../../../utils/data'
import DatePicker from 'react-datepicker'
import '../../../components/DatePicker/index.css'
import { ptBR } from 'date-fns/locale'

Chart.register(CategoryScale)

const columnCelStyle = {
	width: '150px',
	padding: '6px 0',
	border: '1px solid white',
}

export default function Reports(){
	// https://blog.logrocket.com/using-chart-js-react/
	// https://vb.rebelbetting.com/bets

	const [byReturn, setByReturn] = useState(false)
	const [stake, setStake] = useState(100)
	const [reportsData, setReportsData] = useState({} as any)
	const [timeDate, setTimeDate] = useState('month')
	const [startDate, setStartDate] = useState<Date | null>(null)
	const [endDate, setEndDate] = useState<Date | null>(null)
	let timeoutChangeStake: NodeJS.Timeout | null = null

	function resetData(){

		setReportsData({
			timeAbout: '',
			totalBets: 0,
			totalProfit: 0,
			averageOdds: 0,
			ROI: 0,
			averageCLV: 0,
			averagePercentage: 0,
			betsOnDate: [] as any[],
			graphics: {
				totalCLVProfits: [] as number[],
				totalProfits: [] as number[]
			}
		})

	}

	async function getData(timeData = ''){
		// 'forever'

		if(timeData) setTimeDate(timeData)

		const response = await ApiRequest('/user/get/reports/bet365', { timeData, byReturn, stake, startDate, endDate })

		if(response.status != 400){

			const totalProfits: number[] = []
			const totalCLVProfits: number[] = []

			const profits = Object.values(response.data.betsOnDate).map((el: any) => parseFloat(el.profit))
			const CLVProfits = Object.values(response.data.betsOnDate).map((el: any) => parseFloat(el.CLVProfit))

			// console.log({ profits })

			for(let i = 0; i < profits.length; i++){

				totalProfits.push(
					(totalProfits[i - 1] || 0) + profits[i]
				)

				totalCLVProfits.push(
					(totalCLVProfits[i - 1] || 0) + CLVProfits[i]
				)

			}

			console.log({ data: response.data })

			setReportsData({
				...response.data,
				graphics: {
					totalProfits,
					totalCLVProfits
				}
			})

		}

	}

	useEffect(() => {

		if(timeDate != 'custom'){

			resetData()
			getData(timeDate)

		}

	}, [timeDate])

	useEffect(() => {

		resetData()
		getData(timeDate)

	}, [byReturn])

	useEffect(() => {

		if(timeoutChangeStake) clearTimeout(timeoutChangeStake)
		resetData()
		timeoutChangeStake = setTimeout(() => getData(timeDate), 3000)

	}, [stake])

	useEffect(() => {

		if(endDate){

			resetData()
			getData(timeDate)

		}

	}, [endDate])

	useEffect(() => {

		getData()

	}, [])

	const generalDataStyle: React.CSSProperties = {
		margin: '8px',
		padding: '12px',
		width: '35%',
		border: '1px solid white',

		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	}

	const translateTableTitle = {
		odds: 'Odds',
		fairOdds: 'Aposta de Valor',
		result: 'Resultado'
	}

	const translateResultTable = {
		Win: '✅',
		HalfWin: '🔄/✅',
		Lose: '❌',
		HalfLose: '🔄/❌',
		Void: '🔄',
	}

	const resultsLengthIndex = !reportsData.totalBets ? 0 : parseInt((reportsData.graphics.totalProfits.length / 10).toFixed(0))

	return (
		<>
			<BackButton />
			<Container>
				<Text
					textOption='title'
				>
					Estatísticas em Tempo Real
				</Text>
			</Container>

			<Container>
				<div style={{ display: 'flex', justifyContent: 'center', margin: '12px 0', gap: '5%' }}>
					<div
						style={{ width: '40%', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #007bff', padding: '4px', borderRadius: '4px', cursor: 'pointer', backgroundColor: byReturn ? 'transparent' : '#3c9aff' }}
						onClick={() => setByReturn(false)}
					>
						Usar valor como Stake
					</div>
					<div
						style={{ width: '40%', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #007bff', padding: '4px', borderRadius: '4px', cursor: 'pointer', backgroundColor: byReturn ? '#3c9aff' : 'transparent' }}
						onClick={() => setByReturn(true)}
					>
						Usar valor como retorno desejado
					</div>
				</div>
				<Input
					onChange={(ev: any) => setStake(parseFloat(ev.target.value))}
					placeholder='100'
					defaultValue='100'
					type='number'
				/>
			</Container>

			<Container>
				<Text
					textOption='subtitle'
				>
					Dados Gerais Bet365
				</Text>
				{
					reportsData.totalBets > 0  ? (
						<>
							<br />
							<Text
								textOption='subtitle'
							>
								Período de referência: {reportsData.timeAbout}
							</Text>
							<br />
							<div style={{
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'center',
							}}>
								<div style={generalDataStyle}>
									Banca Recomendada:
									<br />
									R${moneyValue(stake * 100)}
								</div>
								<div style={generalDataStyle}>
									Stake média:
									<br />
									R${moneyValue(stake)} (1%)
								</div>
								<div style={generalDataStyle}>
									Lucro total:
									<br />
									<span style={{
										color: reportsData.totalProfit >= 0 ? styleguide.backgroundColor.success : styleguide.backgroundColor.error,
										fontWeight: 700
									}}>
										R${moneyValue(reportsData.totalProfit)} / {moneyValue(reportsData.totalProfit / stake)} unidades
									</span>
								</div>
								<div style={generalDataStyle}>
									Qtd apostas:
									<br />
									{reportsData.totalBets}
								</div>
								<div style={generalDataStyle}>
									Odds médias:
									<br />
									{reportsData.averageOdds.toFixed(2)}
								</div>
								<div style={generalDataStyle}>
									ROI:
									<br />
									{(reportsData.ROI * 100).toFixed(2)}%
								</div>
								<div style={generalDataStyle}>
									CLV médio:
									<br />
									{(reportsData.averageCLV * 100).toFixed(2)}%
								</div>
								<div style={generalDataStyle}>
									Porcentagem média:
									<br />
									{(reportsData.averagePercentage * 100).toFixed(2)}%
								</div>
							</div>
						</>
					) : <Loading />
				}

			</Container>

			<Container>
				<Text
					textOption='subtitle'
				>
					Análise Gráfica
				</Text>
				{
					reportsData.totalBets > 0  ? (
						<div
							style={{
								margin: '0 auto',
								width: '80vw',
								maxWidth: '600px',
								height: 'auto',
							}}
						>
							<Line
								data={{
									labels: Object.keys(reportsData.betsOnDate).reverse().filter((el: any, index: number) => index % resultsLengthIndex == 0),
									datasets: [
										{
											label: 'Lucro Total Esperado',
											data: reportsData.graphics.totalCLVProfits.filter((el: any, index: number) => index % resultsLengthIndex == 0).map((el: number) => el.toFixed(2)),
											backgroundColor: [
												'#84b5ff',
											],
											pointBackgroundColor: '#0066ff',
											borderColor: '#0066ff',
											borderWidth: 2,
											tension: 0.4,
										},
										// {
										// 	label: 'Lucro Por Retorno',
										// 	data: reportsData.graphics.totalReturnProfits.filter((el: any, index: number) => index % resultsLengthIndex == 0).map((el: number) => el.toFixed(2)),
										// 	backgroundColor: [
										// 		'#ec3c3c',
										// 	],
										// 	pointBackgroundColor: 'red',
										// 	borderColor: 'red',
										// 	borderWidth: 2,
										// 	tension: 0.4,
										// },
										{
											label: 'Lucro Total',
											data: reportsData.graphics.totalProfits.filter((el: any, index: number) => index % resultsLengthIndex == 0).map((el: number) => el.toFixed(2)),
											backgroundColor: [
												'rgb(136, 191, 77)',
											],
											pointBackgroundColor: '#007902',
											borderColor: '#007902',
											borderWidth: 2,
											fill: true,
											tension: 0.4,
										},
									]
								}}
								options={{
									plugins: {
										title: {
											display: true,
											text: `Lucros de: ${reportsData.timeAbout}`
										}
									}
								}}
							/>
						</div>
					) : <Loading />
				}
			</Container>


			<Container>
				<Text textOption='subtitle' haveMargin>
					Selecione o período que preferir
				</Text>
				<Button onclick={() => setTimeDate('forever')} tosuccess={timeDate == 'forever'} >
					Resultados desde o início
				</Button>
				<Button onclick={() => setTimeDate('year')} tosuccess={timeDate == 'year'}>
					Resultados dos últimos 12 meses
				</Button>
				<Button onclick={() => setTimeDate('trimester')} tosuccess={timeDate == 'trimester'}>
					Resultados dos últimos 3 meses
				</Button>
				<Button onclick={() => setTimeDate('sixmester')} tosuccess={timeDate == 'sixmester'}>
					Resultados dos últimos 6 meses
				</Button>
				<Button onclick={() => setTimeDate('month')} tosuccess={timeDate == 'month'}>
					Resultados dos últimos 30 dias
				</Button>
				<Button onclick={() => setTimeDate('week')} tosuccess={timeDate == 'week'}>
					Resultados dos últimos 7 dias
				</Button>
				<Button onclick={() => setTimeDate('custom')} tosuccess={timeDate == 'custom'}>
					Customizar
				</Button>

				{ timeDate == 'custom' && <>
					<Text textOption='subtitle' haveMargin>
						Escolha exatamente o período de análise
					</Text>
					<DatePicker
						selected={new Date}
						onChange={(dates) => {
							const [start, end] = dates
							// console.log({ start, end })
							setStartDate(start)
							setEndDate(end)
						}}
						startDate={startDate}
						endDate={endDate}
						selectsRange
						inline
						locale={ptBR}
					/>
				</> }
			</Container>

			<Container>
				<table style={{
					margin: '12px auto',
					padding: '4px',
					borderCollapse: 'collapse'
				}}>
					<tr>
						<th style={columnCelStyle}>---</th>
						<th style={columnCelStyle}>
							Quantidade
						</th>
						<th style={columnCelStyle}>
							Ganhos
						</th>
						<th style={columnCelStyle}>
							Apostas
						</th>
					</tr>
					<tr>
						<td style={{...columnCelStyle, backgroundColor: styleguide.backgroundColor.success}}>Greens</td>
						<td style={{...columnCelStyle, backgroundColor: styleguide.backgroundColor.success}}>{reportsData?.betsOnResolutionType?.Win?.qtt || 0}</td>
						<td style={{...columnCelStyle, backgroundColor: styleguide.backgroundColor.success}}>R${moneyValue(reportsData?.betsOnResolutionType?.Win?.profit || 0)}</td>
						<td style={{...columnCelStyle, backgroundColor: styleguide.backgroundColor.success}}>R${moneyValue(reportsData?.betsOnResolutionType?.Win?.stake || 0)}</td>
					</tr>
					<tr>
						<td style={{...columnCelStyle, backgroundColor: styleguide.backgroundColor.successSemi}}>Meio Greens</td>
						<td style={{...columnCelStyle, backgroundColor: styleguide.backgroundColor.successSemi}}>{reportsData?.betsOnResolutionType?.HalfWin?.qtt || 0}</td>
						<td style={{...columnCelStyle, backgroundColor: styleguide.backgroundColor.successSemi}}>R${moneyValue(reportsData?.betsOnResolutionType?.HalfWin?.profit || 0)}</td>
						<td style={{...columnCelStyle, backgroundColor: styleguide.backgroundColor.successSemi}}>R${moneyValue(reportsData?.betsOnResolutionType?.HalfWin?.stake || 0)}</td>
					</tr>
					<tr>
						<td style={{...columnCelStyle, backgroundColor: styleguide.backgroundColor.editButton}}>Devoluções</td>
						<td style={{...columnCelStyle, backgroundColor: styleguide.backgroundColor.editButton}}>{reportsData?.betsOnResolutionType?.Void?.qtt || 0}</td>
						<td style={{...columnCelStyle, backgroundColor: styleguide.backgroundColor.editButton}}>R${moneyValue(reportsData?.betsOnResolutionType?.Void?.profit || 0)}</td>
						<td style={{...columnCelStyle, backgroundColor: styleguide.backgroundColor.editButton}}>R${moneyValue(reportsData?.betsOnResolutionType?.Void?.stake || 0)}</td>
					</tr>
					<tr>
						<td style={{...columnCelStyle, backgroundColor: styleguide.backgroundColor.errorSemi}}>Meio Reds</td>
						<td style={{...columnCelStyle, backgroundColor: styleguide.backgroundColor.errorSemi}}>{reportsData?.betsOnResolutionType?.HalfLose?.qtt || 0}</td>
						<td style={{...columnCelStyle, backgroundColor: styleguide.backgroundColor.errorSemi}}>R${moneyValue(reportsData?.betsOnResolutionType?.HalfLose?.profit || 0)}</td>
						<td style={{...columnCelStyle, backgroundColor: styleguide.backgroundColor.errorSemi}}>R${moneyValue(reportsData?.betsOnResolutionType?.HalfLose?.stake || 0)}</td>
					</tr>
					<tr>
						<td style={{...columnCelStyle, backgroundColor: styleguide.backgroundColor.error}}>Reds</td>
						<td style={{...columnCelStyle, backgroundColor: styleguide.backgroundColor.error}}>{reportsData?.betsOnResolutionType?.Lose?.qtt || 0}</td>
						<td style={{...columnCelStyle, backgroundColor: styleguide.backgroundColor.error}}>R${moneyValue(reportsData?.betsOnResolutionType?.Lose?.profit || 0)}</td>
						<td style={{...columnCelStyle, backgroundColor: styleguide.backgroundColor.error}}>R${moneyValue(reportsData?.betsOnResolutionType?.Lose?.stake || 0)}</td>
					</tr>
				</table>
			</Container>

			{/* <Container>
				<Text
					textOption='subtitle'
				>
					Ultimas 10 apostas
				</Text>
				{
					!reportsData.last10Bets ? <Loading />
						: reportsData.last10Bets.length == 0 ? <Text>Sem apostas recentes</Text>
							: (
								<table style={{
									margin: '12px auto',
									padding: '4px',
									borderCollapse: 'collapse'
								}}>
									<tr>
										{Object.keys(reportsData.last10Bets[0]).map((el) => (
											<th style={{
												width: '150px',
												padding: '6px 0',
												border: '1px solid white',
											}}>
												{translateTableTitle[el as keyof typeof translateTableTitle]}
											</th>
										))}
									</tr>
									{
										reportsData.last10Bets.map((el: any) => (
											<tr>
												<td style={{
													border: '1px solid white'
												}}>
													{parseFloat(el['odds']).toFixed(2)}
												</td>
												<td style={{
													border: '1px solid white'
												}}>
													+EV
												</td>
												<td style={{
													border: '1px solid white'
												}}>
													{translateResultTable[el['result'] as keyof typeof translateResultTable]}
												</td>
											</tr>
										))
									}
								</table>
							)

				}
			</Container> */}
		</>
	)

}
