import styled from 'styled-components'

export default styled.table`
	border-collapse: collapse;
	width: 100%;

	& td {
		border: 1px solid white;
		padding: 4px 8px;
		max-width: 300px;
	}
`
