import React from 'react'
import StyledTable from './styles'

export default function Table({
	children,
} : {
	children: React.ReactNode
}){

	return (
		<StyledTable>
			{children}
		</StyledTable>
	)

}
