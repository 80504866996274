import { createGlobalStyle } from 'styled-components'

export const styleguide = {
	color: 'white',
	fontSize: {
		title: '24px',
		subtitle: '18px',
		paragraph: '12px',
		button: '16px',
	},
	fontWeight: {
		link: '800'
	},

	backgroundColor: {
		main: 'black',
		container: '#333333',
		button: '#555555',
		editButton: '#007bff',
		error: '#ff0000',
		errorSemi: '#cb3f3f',
		success: 'green',
		successSemi: 'rgb(35, 175, 35)'
	},
	borderRadius: {
		container: '8px',
		button: '4px',
		input: '4px',
	},
	border: {
		input: '1px solid #ccc'
	},

	margin: {
		title: '32px 0',
		subtitle: '16px 0',
		paragraph: '8px 0',

		body: '24px 5vw 24px 5vw',
		popup: '12px 2.5vw 0 2.5vw',
		form: '12px 0',
		input: '12px',
		link: '4px',
		container: '16px 0',
		button: '10px 2px'
	},
	padding: {
		container: '10px',
		input: '6px',
	},

	hover: {
		button: {
			background: '#777777'
		},
		error: {
			background: '#dd0000'
		},
		editButton: {
			background: '#006adb'
		},
		success: {
			background: '#005a00'
		}
	}
}

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
		text-align: center;
		font-family: 'Inter', sans-serif;
		word-wrap: break-word;
    }

    body {
        background-color: black;
        color: white;

		margin: ${styleguide.margin.body};
    }

	body.margin0 {
		margin: 0;
		overflow-x: hidden;
	}

	input {
		color: black;
	}

	button {
		color: white;
	}

	#recaptcha {
		display: flex;
    	justify-content: center;
	}
`
