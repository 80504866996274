import { FormEvent, useEffect, useRef, useState } from 'react'
import Button from '../../../components/Button'
import Column from '../../../components/Column'
import Container from '../../../components/Container'
import Header from '../../../components/Header'
import Row from '../../../components/Row'
import Table from '../../../components/Table'
import Text from '../../../components/Text'
import { styleguide } from '../../../global/Theme'
import Input from '../../../components/Input/Text'
import ApiRequest from '../../../global/Api'
import DatePicker from 'react-datepicker'
import '../../../components/DatePicker/index.css'
import { ptBR } from 'date-fns/locale'

interface IAccount {
	_id: string
    name: string
    daysRunning: string
    initialBalance: string
    finishBalance: string
    date: string
}

const defaultAccountData: IAccount = {
	_id: '',
	name: '',
	daysRunning: '',
	initialBalance: '',
	finishBalance: '',
	date: ''
}

const month = (new Date).getMonth() + 1
const year = (new Date).getFullYear()

function moneyValue(input: number){

	return input.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')

}

export default function ManagmentPage(){

	const accountData = useRef(defaultAccountData)
	const [isVisibleModal, setIsVisibleModal] = useState(false)
	const [isVisibleModalTime, setIsVisibleModalTime] = useState(false)
	const [analyses, setAnalyses] = useState({
		totalProfit: 0,
		monthProfit: 0,
	})

	const [isNew, setIsNew] = useState(true)

	const [data, setData] = useState<any[]>([])

	const [startDate, setStartDate] = useState<Date | null>(null)
	const [endDate, setEndDate] = useState<Date | null>(null)

	useEffect(() => {

		findAccounts()

	}, [])

	useEffect(() => {

		setAnalyses({
			totalProfit: data.reduce((accumulator, currentValue) => accumulator + currentValue.finishBalance - currentValue.initialBalance, 0),
			monthProfit: data.filter(data => data.date.includes(`-${month < 10 ? `0${month}` : month}-`) && data.date.includes(year)).reduce((accumulator, currentValue) => accumulator + currentValue.finishBalance - currentValue.initialBalance, 0)
		})

	}, [data])

	const Rows = function(){

		return <>
			{
				data
					.filter((row) => isDateInRange(row.date))
					.map((row) => <tr
						style={{ cursor: 'pointer' }}
						onClick={() => {
							accountData.current = row
							setIsVisibleModal(true)
							setIsNew(false)
						}}
					>
						<td>{ row.date }</td>
						<td>{ row.name }</td>
						<td>{ row.daysRunning }</td>
						<td>R$ { moneyValue(row.initialBalance) }</td>
						<td>R$ { moneyValue(row.finishBalance) }</td>
						<td style={{ backgroundColor: row.finishBalance - row.initialBalance >= 0 ? styleguide.backgroundColor.success : styleguide.backgroundColor.error }}>R$ { moneyValue(row.finishBalance - row.initialBalance) }</td>
					</tr>)
			}
		</>

	}

	const ModalForm = () => {

		return <>
			<div
				style={{
					width: '100vw',
					height: '100vh',
					background: 'black',
					opacity: 0.5,
					position: 'fixed',
					left: 0,
					top: 0,
					zIndex: 5,
				}}
			/>
			<div
				style={{
					position: 'fixed',
					margin: 'auto',
					left: '20vw',
					top: '10vh',
					width: '60vw',
					background: styleguide.backgroundColor.container,
					padding: styleguide.padding.container,
					borderRadius: styleguide.borderRadius.container,
					border: styleguide.border.input,
					zIndex: 10,
				}}
			>
				<p
					onClick={() => setIsVisibleModal(false)}
					style={{ width: '100%', textAlign: 'right', cursor: 'pointer' }}
				>
					X
				</p>
				<Text textOption='subtitle'>
					{ isNew ? 'Crie sua nova conta' : 'Edite sua conta' }
				</Text>
				<form
					style={{ marginTop: styleguide.margin.input }}
					onSubmit={isNew ? createAccount : updateAccount}
				>
					<div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
						<Input placeholder='Nome da conta' defaultValue={accountData.current.name} onChange={(ev: any) => accountData.current.name = ev.target.value} name='name'>
							Nome da conta
						</Input>
						<Input type='number' defaultValue={accountData.current.daysRunning} onChange={(ev: any) => accountData.current.daysRunning = ev.target.value} name='daysRunning'>
							Dias rodando
						</Input>
						<Input type='number' defaultValue={accountData.current.initialBalance} onChange={(ev: any) => accountData.current.initialBalance = ev.target.value} name='initialBalance'>
							Balanço inicial
						</Input>
						<Input type='number' defaultValue={accountData.current.finishBalance} onChange={(ev: any) => accountData.current.finishBalance = ev.target.value} name='finishBalance'>
							Balanço final
						</Input>
						<Column>
							<Text>Data da conta</Text>
							<input
								style={{
									width: '80%',
									maxWidth: '300px',
									minWidth: '200px',
									marginTop: '12px',
									padding: '6px',
									borderRadius: '6px'
								}}
								type='date'
								defaultValue={accountData.current.date}
								onChange={(ev: any) => accountData.current.date = ev.target.value}
							/>
						</Column>
					</div>
					{ !isNew && <Button
						todelete
						onclick={deleteAccount}
					>
						Deletar
					</Button>}
					<Button
						tosuccess
						onclick={() => null}
					>
						{isNew ? 'Criar' : 'Editar'}
					</Button>
				</form>
			</div>
		</>

	}

	const ModalConfigTime = () => {

		return <>
			<div
				style={{
					width: '100vw',
					height: '100vh',
					background: 'black',
					opacity: 0.5,
					position: 'fixed',
					left: 0,
					top: 0,
					zIndex: 5,
				}}
			/>
			<div
				style={{
					position: 'fixed',
					margin: 'auto',
					left: '20vw',
					top: '10vh',
					width: '60vw',
					background: styleguide.backgroundColor.container,
					padding: styleguide.padding.container,
					borderRadius: styleguide.borderRadius.container,
					border: styleguide.border.input,
					zIndex: 10,
				}}
			>
				<p
					onClick={() => setIsVisibleModalTime(false)}
					style={{ width: '100%', textAlign: 'right', cursor: 'pointer' }}
				>
					X
				</p>
				<Column>
					<DatePicker
						selected={new Date}
						onChange={(dates) => {
							const [start, end] = dates
							setStartDate(start)
							setEndDate(end)
							if(end) setIsVisibleModalTime(false)
						}}
						startDate={startDate}
						endDate={endDate}
						selectsRange
						inline
						locale={ptBR}
					/>
					<Button
						onclick={() => {
							setStartDate(null)
							setEndDate(null)
							setIsVisibleModalTime(false)
						}}
					>
						Analisar desde o início
					</Button>
				</Column>
			</div>
		</>

	}

	const isDateInRange = (date: string): boolean => {
		if (!startDate || !endDate) return true

		const checkDate = new Date(date)

		const startTimestamp = startDate.getTime()
		const endTimestamp = endDate.getTime()
		const checkTimestamp = checkDate.getTime()

		return checkTimestamp >= startTimestamp && checkTimestamp <= endTimestamp
	}

	async function findAccounts(){

		const response = await ApiRequest('/managment/rows', {}, 'user')
		console.log(response)
		if(response.status == 200) setData(response.data)

	}


	async function createAccount(ev: React.FormEvent<HTMLFormElement>){

		ev.preventDefault()

		const response = await ApiRequest('/managment/row/create', accountData.current, 'user')

		if(response.status == 200){

			findAccounts()
			setIsVisibleModal(false)
			accountData.current = defaultAccountData

		}

	}

	async function updateAccount(ev: React.FormEvent<HTMLFormElement>){

		ev.preventDefault()

		const response = await ApiRequest(`/managment/row/update/${accountData.current._id}`, accountData.current, 'user')

		if(response.status == 200){

			findAccounts()
			setIsVisibleModal(false)
			accountData.current = defaultAccountData

		}

	}

	async function deleteAccount(){

		const response = await ApiRequest(`/managment/row/delete/${accountData.current._id}`, {}, 'user')

		if(response.status == 200){

			findAccounts()
			setIsVisibleModal(false)
			accountData.current = defaultAccountData

		}

	}

	return <>
		<Header />
		<Container>
			<Text
				textOption='title'
			>
				Gerenciador de Contas Limitadas
			</Text>
		</Container>
		<Container>
			<Text textOption='subtitle'>
				Meus resultados
			</Text>
			<p>
				({data.length} contas)
			</p>
			<Row>
				<Column style={{ padding: '8px', margin: '4px', width: '40%', borderRadius: '4px', border: '1px solid green' }}>
					<p>Contas Limitadas Positivas</p>
					<p>{data.filter(row => row.initialBalance <= row.finishBalance).length}</p>
				</Column>
				<Column style={{ padding: '8px', margin: '4px', width: '40%', borderRadius: '4px', border: '1px solid red' }}>
					<p>Contas Limitadas Negativas</p>
					<p>{data.filter(row => row.initialBalance > row.finishBalance).length}</p>
				</Column>
			</Row>
			<Row>
				<Column style={{ background: analyses.totalProfit >= 0 ? 'green' : 'red', padding: '8px', margin: '4px', width: '40%', borderRadius: '4px', border: '1px solid white' }}>
					<p>Lucro Total</p>
					<p>R$ {moneyValue(analyses.totalProfit)}</p>
				</Column>
				<Column style={{ background: analyses.monthProfit >= 0 ? 'green' : 'red', padding: '8px', margin: '4px', width: '40%', borderRadius: '4px', border: '1px solid white' }}>
					<p>Lucro no Mês ({ month < 10 ? `0${month}` : month }/{ year })</p>
					<p>R$ { moneyValue(analyses.monthProfit)}
					</p>
				</Column>
				{/* <Column style={{ background: analyses.totalProfit >= 0 ? 'green' : 'red', padding: '8px', margin: '4px', width: '40%', borderRadius: '4px', border: '1px solid white' }}>
					<p>Lucro Total Por Conta</p>
					<p>R$ { moneyValue(analyses.totalProfit/data.length || 0)}</p>
				</Column>
				<Column style={{ background: analyses.totalProfit >= 0 ? 'green' : 'red', padding: '8px', margin: '4px', width: '40%', borderRadius: '4px', border: '1px solid white' }}>
					<p>Lucro no Mês Por Conta ({ month < 10 ? `0${month}` : month }/{ year })</p>
					<p>R$ { moneyValue(analyses.monthProfit/data.length || 0)}
					</p>
				</Column> */}
			</Row>
			<Column>
				<Button
					toedit
					onclick={() => setIsVisibleModalTime(true)}
				>
					Mudar período de análise
				</Button>
				<p>
					Período de análise: {
						!startDate || !endDate
							? 'Desde o início'
							: `📅 Início: ${startDate.toLocaleDateString()} - 📅 Fim: ${endDate.toLocaleDateString()}`
					}
				</p>
			</Column>
		</Container>
		<Container>
			<Table>
				<thead>
					<td style={{ backgroundColor: styleguide.backgroundColor.editButton }}>Data</td>
					<td style={{ backgroundColor: styleguide.backgroundColor.editButton }}>Login conta limitada</td>
					<td style={{ backgroundColor: styleguide.backgroundColor.editButton }}>Dias Rodando</td>
					<td style={{ backgroundColor: styleguide.backgroundColor.editButton }}>Saldo Inicial</td>
					<td style={{ backgroundColor: styleguide.backgroundColor.editButton }}>Saldo Final</td>
					<td style={{ backgroundColor: styleguide.backgroundColor.editButton }}>Resultado</td>
				</thead>
				<tbody>
					<Rows />
				</tbody>
			</Table>
			{ data.length == 0 && <div style={{ display: 'block', width: '100%', margin: '24px auto' }}>
				<Text textOption='subtitle'>Sem dados</Text>
			</div>
			}
		</Container>
		<Button
			toedit
			onclick={() => {
				setIsVisibleModal(true)
				setIsNew(true)
			}}
		>
			Criar conta
		</Button>

		{isVisibleModal && <ModalForm />}
		{isVisibleModalTime && <ModalConfigTime />}
	</>

}
